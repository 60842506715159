@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body,
html {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   background: linear-gradient(to right, white 0%, beige 20%, beige 80%, white 100%);
   background: #8a5555;
}

@media screen and (orientation: portrait) {
   body,
   html {
      width: 100%;
      height: 100%;
      border-radius: 0;   background: #e6e6e6;

   }
}
body {
   font-family: Poppins, sans-serif;
}
